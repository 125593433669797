/* custom fonts*/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/* default css*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

body::-webkit-scrollbar {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

/************ Navbar mobile Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

span.navbar-text .social-icon {
  padding-bottom: 12px;
}



.custom-navbar-collapse {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  text-align: center;
}

.custom-navbar-collapse .navbar-nav {
  display: flex;
  flex-direction: column;
  align-items: right;
  font-size: 44px;
}

.custom-navbar-collapse .navbar-nav .nav-link {
  padding: 15px 0;
  font-size: 44px;
}

.custom-navbar-collapse .navbar-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* Desktop Navbar Styles */
.desktop-navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

.desktop-navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

.desktop-navbar .desktop-navbar-nav .desktop-nav-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

.desktop-navbar .desktop-navbar-nav .desktop-nav-link:hover,
.desktop-navbar .desktop-navbar-nav .desktop-nav-link.active {
  opacity: 1;
}

.desktop-navbar-text {
  display: flex;
  align-items: center;
}

.desktop-navbar-text .desktop-social-icon {
  padding-bottom: 12px;
}

.desktop-custom-navbar-collapse {
  display: flex;
  justify-content: space-between;
}

.desktop-custom-navbar-collapse .desktop-navbar-nav {
  display: flex;
  align-items: center;
}

.desktop-custom-navbar-collapse .desktop-navbar-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-left: auto;
}

@media (min-width: 992px) {
  .desktop-navbar-collapse {
    justify-content: space-between;
  }

  .desktop-navbar-collapse .desktop-me-auto {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .desktop-navbar-collapse .desktop-navbar-text {
    margin-left: auto;
  }
}



/*social icons*/
.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 6%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6%;
  transform: scale(0);
    transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
/*text-button*/
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  height: 42px;
  border-radius: 4%;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 4%;
}

.navbar-text button:hover {
  color: #121212;
  background-color: #fff;
}

.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}


@media (max-width: 768px) {
  .navbar {
    padding: 10px; /* Reduces padding */
  }

  .navbar-brand img {
    width: 150px; /* Adjusts logo size */
  }

  .navbar-nav .nav-link {
    padding: 8px 12px; /* Adjusts padding */
  }
  
  .custom-navbar-collapse {
    background-color: #121212; /* Replace with the color of your navbar */
    display: block;
  }

  .navbar-collapse .nav-link {
    padding: 10px 15px; /* More padding around links */
    text-align: center; /* Center-align text */
  }

 
  
  span.navbar-text {
    align-items: center;
  }

}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 62px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  
  font-size: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  height: 42px;
  border-radius: 4%;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

.wrap {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.fade {
  opacity: 0;
}

/* Banner adjustments for mobile screens */
@media (max-width: 768px) {
  .banner {
    padding: 120px 0 60px; /* Adjust padding */
  }

  .banner .tagline {
    font-size: 16px; /* Adjust tagline font size */
  }

  .banner h1 {
    font-size: 32px; /* Adjust main heading font size */
  }

  .banner p {
    font-size: 14px; /* Adjust paragraph font size */
  }

  .banner button {
    padding: 10px 20px; /* Adjust button padding */
    font-size: 16px; /* Adjust button font size */
  }

  .banner img {
    width: 100%; /* Make image responsive */
    height: auto; /* Adjust height automatically */
  }
}


/************ Skills Css ************/

.skill {
  padding: 0 0 50px 0;
  position: relative;
}

.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -40px;
}

.skill h2 {
	font-size: 55px;
	font-weight: 700;
}

.skill h3 {
  padding-top: 50px
}

.skill .item h5 {
  padding-top: 30px;
}

.skill p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img {
  max-width: 100%; /* Ensure images don't exceed the item width */
  height: 80px; /* Adjust height automatically to maintain aspect ratio */
  object-fit: contain; /* Ensure the image is fully visible */
  margin: 0 auto; /* Center the image within the item */
}

.skill-slider .item {
  text-align: center; /* Center the text (and image if needed) within the item */
  padding: 10px; /* Add some padding around the items */
}


/* Skills adjustments for mobile screens */
@media (max-width: 768px) {
  .skill .skill-bx {
    padding: 30px 15px; /* Adjust padding */
  }

  .skill h2 {
    font-size: 24px; /* Adjust heading font size */
  }

  .skill p {
    font-size: 14px; /* Adjust paragraph font size */
  }

  .skill-slider .item img {
    width: 80%; /* Adjust carousel image width */
    margin-bottom: 10px; /* Adjust margin below image */
  }

  .skill-slider .item h5 {
    font-size: 16px; /* Adjust carousel item heading font size */
  }

  /* Adjustments for the Technologies section if used */
  .tech-item img {
    width: 50%; /* Adjust technology icons size */
  }

  .tech-item h5 {
    font-size: 14px; /* Adjust technology name font size */
  }
}


/************ Contact Css ************/
.contact {
  padding-top: 160px;
  padding-bottom: 160px;
}

.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
} 

.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  border-color: #FFFFFF;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 4px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
  border-color: #FFFFFF;
  background: #121212;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Contact Css ************/
.contact form input, .contact form textarea, .thank-you-message p {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

/* Adjustments for the thank-you message to align with form input/textarea styles */
.thank-you-message p {
  background: none; /* No background for text */
  border: none; /* No border for text */
  margin: 20px 0; /* Adjust margin for visual spacing */
  padding: 0; /* No padding needed for text */
  font-size: 18px; /* Matching font size */
  color: #fff; /* White text color for visibility */
}

.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}




/* Contact adjustments for mobile screens */
@media (max-width: 768px) {
  .contact h2 {
    font-size: 24px; /* Adjust heading font size */
  }

  .contact form input,
  .contact form textarea {
    width: 100%; /* Full width for inputs */
    padding: 12px; /* Adjust padding for inputs */
    font-size: 14px; /* Adjust font size for inputs */
  }

  .contact form button {
    padding: 12px 20px; /* Increase button padding */
    font-size: 16px; /* Increase button font size */
  }

  .contact .personal-info h3 {
    font-size: 20px; /* Adjust personal info heading font size */
  }

  .contact .personal-info p {
    font-size: 14px; /* Adjust personal info paragraph font size */
  }
}

.contact button, .contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border-color: #FFFFFF;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 4px;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact button span, .contact form button span {
  z-index: 1;
  position: relative;
}

.contact button:hover, .contact form button:hover {
  color: #fff;
  border-color: #FFFFFF;
  background: #121212;
}

.contact button::before, .contact form button::before {
  content: "";
  background: #121212;
  border-color: #FFFFFF;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact button:hover::before, .contact form button:hover::before {
  width: 100%;
}


/************ Footer Css ************/

.footer {
  padding: 0 0 50px 0;
  background: #151515;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

/************ Newsletter Css ************/
.newsletter-bx {
  background: #FFFFFF;
  border-radius: 5px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

.footer {
  padding-top: 30px;
}
.footer img {
  width: 36%;
  display: flex;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

/* Footer adjustments for mobile screens */
@media (max-width: 768px) {
  .footer {
    padding: 20px 0; /* Adjust padding */
  }

  .footer img {
    max-width: 100%; /* Full width for logo */
    height: auto; /* Adjust height automatically */
    margin-bottom: 10px; /* Margin below logo */
  }

  .footer .social-icon img {
    width: 30px; /* Adjust social icon size */
    height: 30px; /* Adjust social icon size */
    margin: 0 5px; /* Adjust margin between icons */
  }

  .footer p {
    font-size: 14px; /* Adjust paragraph font size */
  }

  .text-center.text-sm-end {
    text-align: center; /* Center text for smaller screens */
  }

  .footer .col-sm-6:first-child {
    text-align: center; /* Center align the logo column */
    margin-bottom: 10px; /* Add some margin below for spacing */
  }
}


/* Newsletter adjustments for mobile screens */
@media (max-width: 768px) {
  .newsletter-bx h3 {
    font-size: 20px; /* Adjust heading font size */
    margin-bottom: 15px; /* Adjust bottom margin for heading */
  }

  .new-email-bx input {
    width: 100%; /* Full width for input */
    padding: 10px; /* Adjust padding for input */
    font-size: 14px; /* Adjust font size for input */
    margin-bottom: 10px; /* Margin below input */
  }

  .new-email-bx button {
    width: 100%; /* Full width for button */
    padding: 12px 20px; /* Increase button padding */
    font-size: 16px; /* Increase button font size */
  }

  .newsletter-bx .alert {
    font-size: 14px; /* Adjust font size for alerts */
    margin-top: 10px; /* Margin above alert */
  }
}






.custom-projects-section {
  padding: 2rem 0;
}

.custom-heading {
  text-align: center;
  margin-bottom: 2rem;
}

.custom-view-more-link {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.custom-view-more-button {
  padding: 0.5rem 2rem;
  font-size: 1rem;
  cursor: pointer;
}

.project-card {
  margin: 0 auto; /* Center the card horizontally */
}

.project-card .card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-card .card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


/************ Main Css ************/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

